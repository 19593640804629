import {Buffer} from 'buffer';


/* host names */
const UAT_HOST: string = 'uat01.kshema.co';
const POC_HOST: string = 'poc01.kshema.co';

/* Change this to true to use uat */
const isFromUAT: boolean = true;

const CURRENT_HOST: string =isFromUAT ? UAT_HOST : POC_HOST;

// entry path
//TODO
const BASE_PATH: string = '';

// environment
const CURRENT_PATH: string = 'https://' + CURRENT_HOST + '/' + BASE_PATH;

// types of methods
export const method: {
  DELETE: string;
  POST: string;
  GET: string;
  PUT: string;
  PATCH: string;
  OPTIONS: string;
} = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  OPTIONS: 'OPTIONS',
};

//TODO
const credentials = {
  Username: '',
  Password: 'i luv Kshema',
};

// api paths
export const paths = {
  // base URL
  BASE_URL: CURRENT_PATH,

  // Encode credentials in Base64
  basicAuthString: Buffer.from(
    `${credentials.Username}:${credentials.Password}`,
  ).toString('base64'),

  AUTHENTICATION_ENDPOINT: 'REST/authentication',
  GET_SEASONS_DATA_ENDPOINT: 'iecops/iecOps/get-seasons',
  GET_ALL_STATES_ENDPOINT: 'chmops/chmOps/get-states',
  GET_ALL_DISTRICTS_ENDPOINT: 'chmops/chmOps/get-districts',
  GET_ALL_TEHSIL_ENDPOINT: 'chmops/chmOps/get-mandals',
  GET_ACTIVITIES_ENDPOINT: 'iecops/iecOps/get-iec-activities',
  GET_IEC_INFO_ENDPOINT: 'iecops/iecOps/iec-info',
  GET_FORM_DATA_ENDPOINT: 'iecops/iecOps/iec-form-data',
  GET_Images_ENDPOINT: 'iecops/iecOps/get-images',
  GET_EXCEL_Download_ENDPOINT: 'iecops/iecOps/excel-download',
  GET_SURVEYOR_ENDPOINT: 'iecops/iecOps/get-surveyors-data',
};


export const METHOD = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
};

